import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  useBreakpoints,
  useElementWidth,
} from "@ottomotors/ottomotors-common/hooks";
import { SVG } from "@ottomotors/ottomotors-common/components";
import * as styles from "./styles.module.scss";

const AnchorLinksSide = ({
  anchorLinks,
}: {
  anchorLinks: ({ id: string; label: string } | null)[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { ref, width } = useElementWidth();
  const { tablet } = useBreakpoints();

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.scrollY <= 0);

      // Find active link index
      const sections = anchorLinks.map((link) =>
        document.getElementById(link?.id.substring(1) || "")
      );
      const topPad = window.innerHeight * 0.3;
      let currentSectionIndex = 0;

      sections.forEach((section, index) => {
        if (section) {
          if (section.offsetTop - topPad < scrollY) {
            currentSectionIndex = index;
          }
        }
      });

      setActiveIndex(currentSectionIndex);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleAnchorLinkClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();

    // Scroll to anchor
    const element = document.getElementById(id.replace(/^#/g, ''));
    element?.scrollIntoView();

    setIsOpen(false);
  };

  return (
    <>
      <button
        className={cn("label", styles.mobileToggleButton)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <SVG svg="x" className={cn(styles.xSvg)} />
        ) : (
          <SVG svg="route" className={cn(styles.routeSvg)} />
        )}
      </button>
      <div
        className={cn(
          styles.container,
          { [styles.visible]: tablet && (!atTop || isHovering) },
          { [styles.open]: !tablet && isOpen }
        )}
        style={{ "--inner-width": `${width}px` }}
        onPointerOver={() => setIsHovering(true)}
        onPointerOut={() => setIsHovering(false)}
      >
        <p className={cn("b2", styles.mobileTitle)}>On this page:</p>
        <div className={cn(styles.inner)} ref={ref}>
          {anchorLinks.map((link, index) => {
            const isActive = index === activeIndex;
            if (!link?.id) {
              return null;
            }
            return (
              <p
                key={index}
                className={cn("label", styles.link, {
                  [styles.active]: isActive,
                })}
              >
                <a href={link.id} onClick={(e) => handleAnchorLinkClick(e, link?.id)}>
                  {link.label}
                </a>
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AnchorLinksSide;
